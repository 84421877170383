class wzCrossSellBanner {

    constructor() {
        this.wzModal = $('.wz-xsell-modal').length > 0 ? $('.wz-xsell-modal') : '';
        this.wzModalOverlay = $('.wz-overlay').length > 0 ? $('.wz-overlay') : '';
        this.termsApply = $('.wz-crossmarketing_terms_inner a.welcome_promoterms').length > 0 ? $('.wz-crossmarketing_terms_inner a.welcome_promoterms') : '';
        this.secondTermsApply = $('#second_promoterms');
        this.bannerRulls = {
            DV: {
                DEFAULT: {
                    URL:"https://ivyaffsolutions.com/C.ashx?btag=a_29262b_867c_&affid=347&siteid=29262&adid=867&c=",
                    URL_HVP:"https://ivyaffsolutions.com/C.ashx?btag=a_30757b_865c_&affid=347&siteid=30757&adid=865&c=",
                    TYPE: "wo",
                    GOTO: "bz"
                },
                FI: {
                    URL:"https://ivyaffsolutions.com/C.ashx?btag=a_29262b_866c_&affid=347&siteid=29262&adid=866&c=",
                    URL_HVP:"https://ivyaffsolutions.com/C.ashx?btag=a_30757b_864c_&affid=347&siteid=30757&adid=864&c=",
                    TYPE: "wo",
                    GOTO: "bz"
                },
                GB: {
                    URL:"https://ivyaffsolutions.com/C.ashx?btag=a_29262b_867c_&affid=347&siteid=29262&adid=867&c=",
                    URL_HVP:"https://ivyaffsolutions.com/C.ashx?btag=a_30757b_865c_&affid=347&siteid=30757&adid=865&c=",
                    TYPE: "wo",
                    GOTO: "bz"
                }
            },
            JV: {
                DEFAULT: {
                    URL:"https://ivyaffsolutions.com/C.ashx?btag=a_28336b_866c_&affid=347&siteid=28336&adid=866&c=",
                    URL_HVP:"https://ivyaffsolutions.com/C.ashx?btag=a_30758b_864c_&affid=347&siteid=30758&adid=864&c=",
                    TYPE: "wo",
                    GOTO: "bz"
                },
                GB: {
                    URL:"https://ivyaffsolutions.com/C.ashx?btag=a_28336b_867c_&affid=347&siteid=28336&adid=867&c=",
                    URL_HVP:"https://ivyaffsolutions.com/C.ashx?btag=a_30758b_865c_&affid=347&siteid=30758&adid=865&c=",
                    TYPE: "wo",
                    GOTO: "bz"
                }
            },
            GI: {
                DEFAULT: {
                    URL:"https://ivyaffsolutions.com/C.ashx?btag=a_28602b_866c_&affid=347&siteid=28602&adid=866&c=",
                    URL_HVP:"https://ivyaffsolutions.com/C.ashx?btag=a_30756b_864c_&affid=347&siteid=30756&adid=864&c=",
                    TYPE: "wo",
                    GOTO: "bz"
                },
                FI: {
                    URL:"https://ivyaffsolutions.com/C.ashx?btag=a_28602b_866c_&affid=347&siteid=28602&adid=866&c=",
                    URL_HVP:"https://ivyaffsolutions.com/C.ashx?btag=a_30756b_864c_&affid=347&siteid=30756&adid=864&c=",
                    TYPE: "wo",
                    GOTO: "bz"
                },
                GB: {
                    URL:"https://ivyaffsolutions.com/C.ashx?btag=a_28602b_867c_&affid=347&siteid=28602&adid=867&c=",
                    URL_HVP:"https://ivyaffsolutions.com/C.ashx?btag=a_30756b_865c_&affid=347&siteid=30756&adid=865&c=",
                    TYPE: "wo",
                    GOTO: "bz"
                }
            },
            SL: {
                DEFAULT: {
                    URL:"https://ivyaffsolutions.com/C.ashx?btag=a_28899b_866c_&affid=347&siteid=28899&adid=866&c=",
                    URL_HVP:"https://ivyaffsolutions.com/C.ashx?btag=a_36218b_864c_&affid=347&siteid=36218&adid=864&c=",
                    TYPE: "wo",
                    GOTO: "bz"
                },
                GB: {
                    URL:"https://ivyaffsolutions.com/C.ashx?btag=a_28899b_867c_&affid=347&siteid=28899&adid=867&c=",
                    URL_HVP:"https://ivyaffsolutions.com/C.ashx?btag=a_36218b_865c_&affid=347&siteid=36218&adid=865&c=",
                    TYPE: "wo",
                    GOTO: "bz"
                }
            },
            SR: {
                DEFAULT: {
                    URL:"https://ivyaffsolutions.com/C.ashx?btag=a_28897b_866c_&affid=347&siteid=28897&adid=866&c=",
                    URL_HVP:"https://ivyaffsolutions.com/C.ashx?btag=a_36222b_864c_&affid=347&siteid=36222&adid=864&c=",
                    TYPE: "wo",
                    GOTO: "bz"
                },
                GB: {
                    URL:"https://ivyaffsolutions.com/C.ashx?btag=a_28897b_867c_&affid=347&siteid=28897&adid=867&c=",
                    URL_HVP:"https://ivyaffsolutions.com/C.ashx?btag=a_36222b_865c_&affid=347&siteid=36222&adid=865&c=",
                    TYPE: "wo",
                    GOTO: "bz"
                }
            },
            SS: {
                DEFAULT: {
                    URL:"https://ivyaffsolutions.com/C.ashx?btag=a_28896b_866c_&affid=347&siteid=28896&adid=866&c=",
                    URL_HVP:"https://ivyaffsolutions.com/C.ashx?btag=a_36223b_864c_&affid=347&siteid=36223&adid=864&c=",
                    TYPE: "wo",
                    GOTO: "bz"
                },
                GB: {
                    URL:"https://ivyaffsolutions.com/C.ashx?btag=a_28896b_867c_&affid=347&siteid=28896&adid=867&c=",
                    URL_HVP:"https://ivyaffsolutions.com/C.ashx?btag=a_36223b_865c_&affid=347&siteid=36223&adid=865&c=",
                    TYPE: "wo",
                    GOTO: "bz"
                }
            },
            TN: {
                DEFAULT: {
                    URL:"https://ivyaffsolutions.com/C.ashx?btag=a_28900b_866c_&affid=347&siteid=28900&adid=866&c=",
                    URL_HVP:"https://ivyaffsolutions.com/C.ashx?btag=a_36220b_864c_&affid=347&siteid=36220&adid=864&c=",
                    TYPE: "wo",
                    GOTO: "bz"
                },
                GB: {
                    URL:"https://ivyaffsolutions.com/C.ashx?btag=a_28900b_867c_&affid=347&siteid=28900&adid=867&c=",
                    URL_HVP:"https://ivyaffsolutions.com/C.ashx?btag=a_36220b_865c_&affid=347&siteid=36220&adid=865&c=",
                    TYPE: "wo",
                    GOTO: "bz"
                }
            },
            CL: {
                DEFAULT: {
                    URL:"https://ivyaffsolutions.com/C.ashx?btag=a_28605b_866c_&affid=347&siteid=28605&adid=866&c=",
                    URL_HVP:"https://ivyaffsolutions.com/C.ashx?btag=a_35769b_864c_&affid=347&siteid=35769&adid=864&c=",
                    TYPE: "wo",
                    GOTO: "bz"
                },
                FI: {
                    URL:"https://ivyaffsolutions.com/C.ashx?btag=a_28605b_874c_&affid=347&siteid=28605&adid=874&c=",
                    URL_HVP:"https://ivyaffsolutions.com/C.ashx?btag=a_35769b_873c_&affid=347&siteid=35769&adid=873&c=",
                    TYPE: "game",
                    GOTO: "dv"
                }
            },
            HK: {
                FI: {
                    URL:"https://ivyaffsolutions.com/C.ashx?btag=a_28606b_874c_&affid=347&siteid=28606&adid=874&c=",
                    URL_HVP:"https://ivyaffsolutions.com/C.ashx?btag=a_35770b_873c_&affid=347&siteid=35770&adid=873&c=",
                    TYPE: "game",
                    GOTO: "dv"
                }
            },
            IC: {
                FI: {
                    URL:"https://ivyaffsolutions.com/C.ashx?btag=a_35686b_866c_&affid=347&siteid=35686&adid=866&c=",
                    URL_HVP:"https://ivyaffsolutions.com/C.ashx?btag=a_35771b_864c_&affid=347&siteid=35771&adid=864&c=",
                    TYPE: "wo",
                    GOTO: "bz"
                }
            },
            LC: {
                DEFAULT: {
                    URL:"https://ivyaffsolutions.com/C.ashx?btag=a_30652b_866c_&affid=347&siteid=30652&adid=866&c=",
                    URL_HVP:"https://ivyaffsolutions.com/C.ashx?btag=a_35772b_864c_&affid=347&siteid=35772&adid=864&c=",
                    TYPE: "wo",
                    GOTO: "bz"
                },
                FI: {
                    URL:"https://ivyaffsolutions.com/C.ashx?btag=a_30652b_874c_&affid=347&siteid=30652&adid=874&c=",
                    URL_HVP:"https://ivyaffsolutions.com/C.ashx?btag=a_35772b_873c_&affid=347&siteid=35772&adid=873&c=",
                    TYPE: "game",
                    GOTO: "dv"
                }
            },
            PT: {
                FI: {
                    URL:"https://ivyaffsolutions.com/C.ashx?btag=a_27610b_874c_&affid=347&siteid=27610&adid=874&c=",
                    URL_HVP:"https://ivyaffsolutions.com/C.ashx?btag=a_35773b_873c_&affid=347&siteid=35773&adid=873&c=",
                    TYPE: "game",
                    GOTO: "dv"
                }
            },
            RC: {
                FI: {
                    URL:"https://ivyaffsolutions.com/C.ashx?btag=a_35687b_874c_&affid=347&siteid=35687&adid=874&c=",
                    URL_HVP:"https://ivyaffsolutions.com/C.ashx?btag=a_35774b_873c_&affid=347&siteid=35774&adid=873&c=",
                    TYPE: "game",
                    GOTO: "dv"
                }
            },
            SP: {
                FI: {
                    URL:"https://ivyaffsolutions.com/C.ashx?btag=a_27609b_874c_&affid=347&siteid=27609&adid=874&c=",
                    URL_HVP:"https://ivyaffsolutions.com/C.ashx?btag=a_35775b_873c_&affid=347&siteid=35775&adid=873&c=",
                    TYPE: "game",
                    GOTO: "dv"
                }
            },
            VT: {
                FI: {
                    URL:"https://ivyaffsolutions.com/C.ashx?btag=a_28604b_874c_&affid=347&siteid=28604&adid=874&c=",
                    URL_HVP:"https://ivyaffsolutions.com/C.ashx?btag=a_35776b_873c_&affid=347&siteid=35776&adid=873&c=",
                    TYPE: "game",
                    GOTO: "dv"
                }
            },
            BZ: {
                FI: {
                    URL:"https://ivyaffsolutions.com/C.ashx?btag=a_28603b_874c_&affid=347&siteid=28603&adid=874&c=",
                    URL_HVP:"https://ivyaffsolutions.com/C.ashx?btag=a_30759b_873c_&affid=347&siteid=30759&adid=873&c=",
                    TYPE: "game",
                    GOTO: "dv"
                },
            },
            BJC: {
                FI: {
                    URL:"https://ivyaffsolutions.com/C.ashx?btag=a_31227b_874c_&affid=347&siteid=31227&adid=874&c=",
                    URL_HVP:"https://ivyaffsolutions.com/C.ashx?btag=a_36347b_873c_&affid=347&siteid=36347&adid=873&c=",
                    TYPE: "game",
                    GOTO: "dv"
                }
            }

        };
        this.isLegacy = ['SR','SL'];
    }

    initModal() {
        console.log('wz-show modal');
        wzCrossSell.wzModal.toggleClass('wz-show');
        wzCrossSell.wzModalOverlay.toggleClass('wz-show');
    }

    closeModal() {
        console.log('close modal');
        wzCrossSell.wzModal.removeClass('wz-show');
        wzCrossSell.wzModalOverlay.removeClass('wz-show');

        $('.second-terms')?.css('display', 'none');
        $('.welcome-terms')?.removeAttr('style');
    }

    setLegacy() {
        return $.inArray(basicInfo.brandshort, this.isLegacy) !== -1 ? true : false;
    }


    initUrl() {
        console.log(basicInfo.brand, ' Init');


        let bannerElement = document.querySelector('.wz-crossmarketing_banner');
        let playerType = bannerElement.getAttribute('data-player');

        let bannerUrlLink = '';
        if(wzCrossSell.bannerRulls[basicInfo.brandshort][basicInfo.geo]) {
            bannerUrlLink = wzCrossSell.bannerRulls[basicInfo.brandshort][basicInfo.geo].URL;
            if(playerType == 'hvp') {
                bannerUrlLink = wzCrossSell.bannerRulls[basicInfo.brandshort][basicInfo.geo].URL_HVP;
            }
        } else if (basicInfo.isLatam && wzCrossSell.bannerRulls[basicInfo.brandshort]['LATAM']) {
            bannerUrlLink = wzCrossSell.bannerRulls[basicInfo.brandshort]['LATAM'].URL;
            if(playerType == 'hvp') {
                bannerUrlLink = wzCrossSell.bannerRulls[basicInfo.brandshort]['LATAM'].URL_HVP;
            }
        } else if (wzCrossSell.bannerRulls[basicInfo.brandshort]['DEFAULT']) {
            bannerUrlLink = wzCrossSell.bannerRulls[basicInfo.brandshort]['DEFAULT'].URL;
            if(playerType == 'hvp') {
                bannerUrlLink = wzCrossSell.bannerRulls[basicInfo.brandshort]['DEFAULT'].URL_HVP;
            }
        }
        console.log(bannerUrlLink);
        if(!wzCrossSell.setLegacy()) {
            wzEventHandler.GAEvent('Click', basicInfo.brand, 'CrossMarketingBanner');
        }
        window.location.href = bannerUrlLink;
    }

    init() {
        if(this.setLegacy()) {
            $('body').on('click', '[data-action="wz.modal.open"]', wzCrossSell.initModal);
            $('body').on('click', '[data-action="wz.modal.close"]',wzCrossSell.closeModal);
            $('body').on('click', '[data-action="goto.crossmarketing"]', wzCrossSell.initUrl);
        }else {
            wzEventHandler.addEvent('wz.modal.open', this.initModal);
            wzEventHandler.addEvent('wz.modal.close', this.closeModal);
            wzEventHandler.addEvent('goto.crossmarketing', this.initUrl);
        }

        if (this.termsApply.length > 0 ) {
            this.termsApply.attr('href', 'javascript:void(0);');
            $('a.wzCMurl').attr('href', 'javascript:void(0);');
            this.termsApply.attr('data-action', 'wz.modal.open');
        }

        this.secondTermsApply.on('click', function () {
            $('.second-terms')?.removeAttr('style');
            $('.welcome-terms')?.css('display', 'none');
        });
    };
}


$(function() {
    var wzCrossSell = new wzCrossSellBanner();
    window.wzCrossSell = wzCrossSell;
    wzCrossSell.init();
});
